export const config = {
    RPC_URL: 'https://cosmoshub-rpc.cosmicvalidator.com',
    REST_URL: 'https://cosmoshub-api.cosmicvalidator.com',
    CHAIN_ID: 'cosmoshub-4',
    CHAIN_NAME: 'Cosmos Network',
    COIN_DENOM: 'ATOM',
    COIN_MINIMAL_DENOM: 'uatom',
    COIN_DECIMALS: 6,
    PREFIX: 'cosmos',
    COINGECKO_ID: 'cosmos',
    SOCKET_URL: 'wss://ccc-api-2023.ctlabs.in/websocket',
};

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_SORT_BY = 'created_at';
export const DEFAULT_ORDER = 'desc';
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;

export const IPFS_URL = 'https://ipfs.omniflix.studio/ipfs';
export const IPFS_REFERENCE_PATH = 'ipfs://';

export const chainId = config.CHAIN_ID;
export const chainName = config.CHAIN_NAME;
export const coinDenom = config.COIN_DENOM;
export const coinMinimalDenom = config.COIN_MINIMAL_DENOM;
export const coinDecimals = config.COIN_DECIMALS;
export const prefix = config.PREFIX;

export const walletExtensions = {
    LEAP: 'https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg',
    COSMO_STATION: 'https://chrome.google.com/webstore/detail/cosmostation-wallet/fpkhgmpbidmiogeglndfbkegfdlnajnf',
    KEPLR: 'https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap',
};

export const bookingLinks = {
    flight: 'https://www.emirates.com/in/english/',
    hotel: 'https://www.hyatt.com/en-US/hotel/united-arab-emirates/grand-hyatt-dubai/dxbgh',
    hackmos: 'https://cosmoverse.org/hackmos',
};

export const cosmoStationChainConfig = {
    chainId: chainId,
    chainName: chainName,
    addressPrefix: prefix,
    baseDenom: coinMinimalDenom,
    displayDenom: coinDenom,
    restURL: config.REST_URL,
    coinType: '118', // optional (default: '118')
    decimals: coinDecimals, // optional (default: 6)
    gasRate: {
        average: '0.0025',
        low: '0.001',
        tiny: '0.025',
    },
};

export const chainConfig = {
    chainId: chainId,
    chainName,
    rpc: config.RPC_URL,
    rest: config.REST_URL,
    stakeCurrency: {
        coinDenom,
        coinMinimalDenom,
        coinDecimals,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${prefix}`,
        bech32PrefixAccPub: `${prefix}pub`,
        bech32PrefixValAddr: `${prefix}valoper`,
        bech32PrefixValPub: `${prefix}valoperpub`,
        bech32PrefixConsAddr: `${prefix}valcons`,
        bech32PrefixConsPub: `${prefix}valconspub`,
    },
    currencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
        },
    ],
    feeCurrencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
        },
    ],
    coinType: 118,
    gasPriceStep: {
        low: 0.01,
        average: 0.025,
        high: 0.04,
    },
};

export const flixConfig = {
    RPC_URL: 'https://rpc.omniflix.network',
    REST_URL: 'https://rest.omniflix.network',
    CHAIN_ID: 'omniflixhub-1',
    CHAIN_NAME: 'OmniFlix Hub',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
};

export const cosmoStationChainConfigFlix = {
    chainId: flixConfig.CHAIN_ID,
    chainName: flixConfig.CHAIN_NAME,
    addressPrefix: flixConfig.PREFIX,
    baseDenom: flixConfig.COIN_MINIMAL_DENOM,
    displayDenom: flixConfig.COIN_DENOM,
    restURL: flixConfig.REST_URL,
    coinType: '118', // optional (default: '118')
    decimals: flixConfig.COIN_DECIMALS, // optional (default: 6)
    gasRate: {
        average: '0.0025',
        low: '0.001',
        tiny: '0.025',
    },
};

export const flixChainConfig = {
    chainId: flixConfig.CHAIN_ID,
    chainName: flixConfig.CHAIN_NAME,
    rpc: flixConfig.RPC_URL,
    rest: flixConfig.REST_URL,
    stakeCurrency: {
        coinDenom: flixConfig.COIN_DENOM,
        coinMinimalDenom: flixConfig.COIN_MINIMAL_DENOM,
        coinDecimals: flixConfig.COIN_DECIMALS,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${flixConfig.PREFIX}`,
        bech32PrefixAccPub: `${flixConfig.PREFIX}pub`,
        bech32PrefixValAddr: `${flixConfig.PREFIX}valoper`,
        bech32PrefixValPub: `${flixConfig.PREFIX}valoperpub`,
        bech32PrefixConsAddr: `${flixConfig.PREFIX}valcons`,
        bech32PrefixConsPub: `${flixConfig.PREFIX}valconspub`,
    },
    currencies: [
        {
            coinDenom: flixConfig.COIN_DENOM,
            coinMinimalDenom: flixConfig.COIN_MINIMAL_DENOM,
            coinDecimals: flixConfig.COIN_DECIMALS,
        },
    ],
    feeCurrencies: [
        {
            coinDenom: flixConfig.COIN_DENOM,
            coinMinimalDenom: flixConfig.COIN_MINIMAL_DENOM,
            coinDecimals: flixConfig.COIN_DECIMALS,
        },
    ],
    coinType: 118,
    gasPriceStep: {
        low: 0.001,
        average: 0.0025,
        high: 0.025,
    },
};
