import { Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { CircularProgress } from '@mui/material';
import NavBar from './containers/NavBar';
import SponsorsRegistration from './containers/MyAccount/SponsorsRegistration';
import SelectTickets from './containers/SelectTickets';
// import BuyTickets from './containers/BuyTickets';

const BuyTickets = lazy(() => import('./containers/BuyTickets').then().catch());
const MyAccount = lazy(() => import('./containers/MyAccount').then().catch());

const routes = [{
    path: '/tickets',
    component: BuyTickets,
}, {
    path: '/tickets/ticketType/:ticketID',
    component: BuyTickets,
}, {
    path: '/tickets/payment/:id',
    component: BuyTickets,
}, {
    path: '/myAccount',
    component: MyAccount,
}, {
    path: '/myAccount/order/:id',
    component: MyAccount,
}, {
    path: '/sponsors',
    component: SponsorsRegistration,
}, {
    path: '/',
    component: SelectTickets,
}];

const Router = () => {
    return (
        <div className="main_content">
            <NavBar/>
            <Suspense fallback={<div className="content_div" style={{ marginTop: '200px' }}><CircularProgress/></div>}>
                <div className="content_div">
                    <Routes>
                        {routes.map((route) =>
                            <Route
                                key={route.path}
                                exact
                                element={<route.component/>}
                                path={route.path}/>,
                        )}
                        <Route
                            exact
                            element={<SelectTickets/>}
                            path="*"/>
                    </Routes>
                </div>
            </Suspense>
        </div>
    );
};

export default Router;
