export const MY_SLOT_ORDERS_FETCH_IN_PROGRESS = 'MY_SLOT_ORDERS_FETCH_IN_PROGRESS';
export const MY_SLOT_ORDERS_FETCH_SUCCESS = 'MY_SLOT_ORDERS_FETCH_SUCCESS';
export const MY_SLOT_ORDERS_FETCH_ERROR = 'MY_SLOT_ORDERS_FETCH_ERROR';

export const SLOT_ORDERS_CREATE_IN_PROGRESS = 'SLOT_ORDERS_CREATE_IN_PROGRESS';
export const SLOT_ORDERS_CREATE_SUCCESS = 'SLOT_ORDERS_CREATE_SUCCESS';
export const SLOT_ORDERS_CREATE_ERROR = 'SLOT_ORDERS_CREATE_ERROR';

export const SLOT_ORDERS_FETCH_IN_PROGRESS = 'SLOT_ORDERS_FETCH_IN_PROGRESS';
export const SLOT_ORDERS_FETCH_SUCCESS = 'SLOT_ORDERS_FETCH_SUCCESS';
export const SLOT_ORDERS_FETCH_ERROR = 'SLOT_ORDERS_FETCH_ERROR';

export const CLEAR_CREATE_SLOT_ORDER = 'CLEAR_CREATE_SLOT_ORDER';

export const SLOT_CONFIRM_DIALOG_SHOW = 'SLOT_CONFIRM_DIALOG_SHOW';
export const SLOT_CONFIRM_DIALOG_HIDE = 'SLOT_CONFIRM_DIALOG_HIDE';
export const SLOT_CONFIRM_DIALOG_SUCCESS_SET = 'SLOT_CONFIRM_DIALOG_SUCCESS_SET';

export const SLOT_STRIP_SECRET_FETCH_IN_PROGRESS = 'SLOT_STRIP_SECRET_FETCH_IN_PROGRESS';
export const SLOT_STRIP_SECRET_FETCH_SUCCESS = 'SLOT_STRIP_SECRET_FETCH_SUCCESS';
export const SLOT_STRIP_SECRET_FETCH_ERROR = 'SLOT_STRIP_SECRET_FETCH_ERROR';

export const SELECTED_MY_AD_SLOT_SET = 'SELECTED_MY_AD_SLOT_SET';
export const CHECKED_MY_AD_SLOT_SET = 'CHECKED_MY_AD_SLOT_SET';

export const UPDATE_OVERLAY_FILE_IN_PROGRESS = 'UPDATE_OVERLAY_FILE_IN_PROGRESS';
export const UPDATE_OVERLAY_FILE_SUCCESS = 'UPDATE_OVERLAY_FILE_SUCCESS';
export const UPDATE_OVERLAY_FILE_ERROR = 'UPDATE_OVERLAY_FILE_ERROR';

export const UPDATE_IN_STREAM_FILE_IN_PROGRESS = 'UPDATE_IN_STREAM_FILE_IN_PROGRESS';
export const UPDATE_IN_STREAM_FILE_SUCCESS = 'UPDATE_IN_STREAM_FILE_SUCCESS';
export const UPDATE_IN_STREAM_FILE_ERROR = 'UPDATE_IN_STREAM_FILE_ERROR';

export const UPDATE_IN_STREAM_FILE_PROGRESS_SET = 'UPDATE_IN_STREAM_FILE_PROGRESS_SET';

export const SELECTED_SLOT_TYPE_SET = 'SELECTED_SLOT_TYPE_SET';

export const SLOT_FILE_FETCH_IN_PROGRESS = 'SLOT_FILE_FETCH_IN_PROGRESS';
export const SLOT_FILE_FETCH_SUCCESS = 'SLOT_FILE_FETCH_SUCCESS';
export const SLOT_FILE_FETCH_ERROR = 'SLOT_FILE_FETCH_ERROR';

export const SLOT_INVOICE_DOWNLOAD_IN_PROGRESS = 'SLOT_INVOICE_DOWNLOAD_IN_PROGRESS';
export const SLOT_INVOICE_DOWNLOAD_SUCCESS = 'SLOT_INVOICE_DOWNLOAD_SUCCESS';
export const SLOT_INVOICE_DOWNLOAD_ERROR = 'SLOT_INVOICE_DOWNLOAD_ERROR';
