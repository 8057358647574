import {
    BUY_TICKETS_EXPANSION_SET,
    CLEAR_CREATE_ORDER,
    CONFIRM_DIALOG_HIDE,
    CONFIRM_DIALOG_SHOW,
    CONFIRM_DIALOG_SUCCESS_SET,
    CONNECT_DIALOG_HIDE,
    CONNECT_DIALOG_SHOW,
    COUPON_CODE_DATA_FETCH_ERROR,
    COUPON_CODE_DATA_FETCH_IN_PROGRESS,
    COUPON_CODE_DATA_FETCH_SUCCESS,
    COUPON_CODE_REMOVE,
    COUPON_CODE_SET,
    CREATE_ORDER_ERROR,
    CREATE_ORDER_IN_PROGRESS,
    CREATE_ORDER_SUCCESS,
    ELIGIBILITY_CHECK_DIALOG_HIDE,
    ELIGIBILITY_CHECK_DIALOG_SHOW,
    ELIGIBILITY_CHECK_FETCH_ERROR,
    ELIGIBILITY_CHECK_FETCH_IN_PROGRESS,
    ELIGIBILITY_CHECK_FETCH_SUCCESS,
    ELIGIBILITY_CHECK_LOGIN_SET,
    EMAIL_ADDRESS_SET,
    FETCH_TICKET_TYPE_ERROR,
    FETCH_TICKET_TYPE_IN_PROGRESS,
    FETCH_TICKET_TYPE_SUCCESS,
    FIRST_NAME_SET,
    FULL_NAME_SET,
    LAST_NAME_SET,
    MY_ORDER_FETCH_ERROR,
    MY_ORDER_FETCH_IN_PROGRESS,
    MY_ORDER_FETCH_SUCCESS,
    NO_OF_TICKETS_SET,
    OTP_VALUE_SET,
    OTP_VERIFY_SET,
    PAYMENT_METHOD_SET,
    STRIP_SECRET_FETCH_ERROR,
    STRIP_SECRET_FETCH_IN_PROGRESS,
    STRIP_SECRET_FETCH_SUCCESS,
    TICKETS_DATA_SET,
    TIME_OUT_DIALOG_HIDE,
    TIME_OUT_DIALOG_SHOW,
} from '../constants/buyTickets';
import {
    CREATE_ORDER_URL,
    FETCH_TICKET_TYPE_URL,
    STRIP_SECRET_FETCH_URL,
    urlEligibilityCheck,
    urlFetchCouponCodeData,
    urlFetchMyOrder,
    urlFetchTicketTypeWithID,
} from '../constants/url';
import Axios from 'axios';

export const showConnectDialog = () => {
    return {
        type: CONNECT_DIALOG_SHOW,
    };
};

export const hideConnectDialog = () => {
    return {
        type: CONNECT_DIALOG_HIDE,
    };
};

export const setTicketExpansion = (value) => {
    return {
        type: BUY_TICKETS_EXPANSION_SET,
        value,
    };
};

export const setNoOfTickets = (value) => {
    return {
        type: NO_OF_TICKETS_SET,
        value,
    };
};

export const setTicketsData = (value, valid) => {
    return {
        type: TICKETS_DATA_SET,
        value,
        valid,
    };
};

export const clearCreateOrder = () => {
    return {
        type: CLEAR_CREATE_ORDER,
    };
};

export const setCouponCode = (value) => {
    return {
        type: COUPON_CODE_SET,
        value,
    };
};

export const removeCouponCode = () => {
    return {
        type: COUPON_CODE_REMOVE,
    };
};

export const setFullName = (value) => {
    return {
        type: FULL_NAME_SET,
        value,
    };
};

export const setFirstName = (value) => {
    return {
        type: FIRST_NAME_SET,
        value,
    };
};

export const setLastName = (value) => {
    return {
        type: LAST_NAME_SET,
        value,
    };
};

export const setEmailAddress = (value, valid) => {
    return {
        type: EMAIL_ADDRESS_SET,
        value,
        valid,
    };
};

export const setOTP = (value) => {
    return {
        type: OTP_VALUE_SET,
        value,
    };
};

export const setOTPVerify = () => {
    return {
        type: OTP_VERIFY_SET,
    };
};

export const setPaymentMethod = (value) => {
    return {
        type: PAYMENT_METHOD_SET,
        value,
    };
};

export const showConfirmDialog = () => {
    return {
        type: CONFIRM_DIALOG_SHOW,
    };
};

export const hideConfirmDialog = () => {
    return {
        type: CONFIRM_DIALOG_HIDE,
    };
};

export const setConfirmDialogSuccess = (value) => {
    return {
        type: CONFIRM_DIALOG_SUCCESS_SET,
        value,
    };
};

export const showTimeOutDialog = () => {
    return {
        type: TIME_OUT_DIALOG_SHOW,
    };
};

export const hideTimeOutDialog = () => {
    return {
        type: TIME_OUT_DIALOG_HIDE,
    };
};

const createOrderInProgress = () => {
    return {
        type: CREATE_ORDER_IN_PROGRESS,
    };
};

export const createOrderSuccess = (value) => {
    return {
        type: CREATE_ORDER_SUCCESS,
        value,
    };
};

const createOrderError = (message) => {
    return {
        type: CREATE_ORDER_ERROR,
        message,
        variant: 'error',
    };
};

export const createOrder = (data, cb) => (dispatch) => {
    dispatch(createOrderInProgress());

    Axios.post(CREATE_ORDER_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(createOrderSuccess(res.data && res.data.result));
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(createOrderError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchTicketTypeInProgress = () => {
    return {
        type: FETCH_TICKET_TYPE_IN_PROGRESS,
    };
};

const fetchTicketTypeSuccess = (value) => {
    return {
        type: FETCH_TICKET_TYPE_SUCCESS,
        value,
        variant: 'success',
    };
};

const fetchTicketTypeError = (message) => {
    return {
        type: FETCH_TICKET_TYPE_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchTicketType = () => (dispatch) => {
    dispatch(fetchTicketTypeInProgress());

    Axios.get(FETCH_TICKET_TYPE_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchTicketTypeSuccess(res.data && res.data.result && res.data.result.list));
        })
        .catch((error) => {
            dispatch(fetchTicketTypeError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const fetchTicketTypeWithID = (id) => (dispatch) => {
    dispatch(fetchTicketTypeInProgress());

    const URL = urlFetchTicketTypeWithID(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchTicketTypeSuccess([res.data && res.data.result]));
        })
        .catch((error) => {
            dispatch(fetchTicketTypeError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchOrderInProgress = () => {
    return {
        type: MY_ORDER_FETCH_IN_PROGRESS,
    };
};

export const fetchOrderSuccess = (value) => {
    return {
        type: MY_ORDER_FETCH_SUCCESS,
        value,
    };
};

const fetchOrderError = (message) => {
    return {
        type: MY_ORDER_FETCH_ERROR,
        message,
    };
};

export const fetchOrder = (id, cb) => (dispatch) => {
    dispatch(fetchOrderInProgress());

    const url = urlFetchMyOrder(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchOrderSuccess(res.data && res.data.result));
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(fetchOrderError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchCouponCodeDataInProgress = () => {
    return {
        type: COUPON_CODE_DATA_FETCH_IN_PROGRESS,
    };
};

export const fetchCouponCodeDataSuccess = (value) => {
    return {
        type: COUPON_CODE_DATA_FETCH_SUCCESS,
        value,
    };
};

const fetchCouponCodeDataError = (message) => {
    return {
        type: COUPON_CODE_DATA_FETCH_ERROR,
        message,
    };
};

export const fetchCouponCodeData = (id, type, cb) => (dispatch) => {
    dispatch(fetchCouponCodeDataInProgress());

    const url = urlFetchCouponCodeData(id, type);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchCouponCodeDataSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchCouponCodeDataError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchStripeSecretInProgress = () => {
    return {
        type: STRIP_SECRET_FETCH_IN_PROGRESS,
    };
};

export const fetchStripeSecretSuccess = (value) => {
    return {
        type: STRIP_SECRET_FETCH_SUCCESS,
        value,
    };
};

const fetchStripeSecretError = (message) => {
    return {
        type: STRIP_SECRET_FETCH_ERROR,
        message,
    };
};

export const fetchStripeSecret = (data, cb) => (dispatch) => {
    dispatch(fetchStripeSecretInProgress());

    Axios.post(STRIP_SECRET_FETCH_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchStripeSecretSuccess(res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchStripeSecretError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

export const showEligibilityCheckDialog = (value, id) => {
    return {
        type: ELIGIBILITY_CHECK_DIALOG_SHOW,
        value,
        id,
    };
};

export const hideEligibilityCheckDialog = () => {
    return {
        type: ELIGIBILITY_CHECK_DIALOG_HIDE,
    };
};

const fetchEligibilityCheckInProgress = () => {
    return {
        type: ELIGIBILITY_CHECK_FETCH_IN_PROGRESS,
    };
};

export const fetchEligibilityCheckSuccess = (value) => {
    return {
        type: ELIGIBILITY_CHECK_FETCH_SUCCESS,
        value,
    };
};

const fetchEligibilityCheckError = (message) => {
    return {
        type: ELIGIBILITY_CHECK_FETCH_ERROR,
        message,
    };
};

export const fetchEligibilityCheck = (id, cb) => (dispatch) => {
    dispatch(fetchEligibilityCheckInProgress());

    const url = urlEligibilityCheck(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchEligibilityCheckSuccess({
                id,
                value: res.data && res.data.remaining_tickets,
            }));
            cb(res.data && res.data.remaining_tickets);
        })
        .catch((error) => {
            dispatch(fetchEligibilityCheckError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null, error.response &&
            error.response.data &&
            error.response.data.message
                ? error.response.data.message
                : 'Failed!');
        });
};

export const setEligibilityCheckLogin = (value, id) => {
    return {
        type: ELIGIBILITY_CHECK_LOGIN_SET,
        value,
        id,
    };
};
