import { DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SKIP, DEFAULT_SORT_BY } from '../config';
import { DISCONNECT_SET } from '../constants/wallet';
import { combineReducers } from 'redux';
import {
    CHECKED_MY_AD_SLOT_SET,
    CLEAR_CREATE_SLOT_ORDER,
    MY_SLOT_ORDERS_FETCH_ERROR,
    MY_SLOT_ORDERS_FETCH_IN_PROGRESS,
    MY_SLOT_ORDERS_FETCH_SUCCESS,
    SELECTED_MY_AD_SLOT_SET,
    SELECTED_SLOT_TYPE_SET,
    SLOT_CONFIRM_DIALOG_HIDE,
    SLOT_CONFIRM_DIALOG_SHOW,
    SLOT_CONFIRM_DIALOG_SUCCESS_SET,
    SLOT_FILE_FETCH_ERROR,
    SLOT_FILE_FETCH_IN_PROGRESS,
    SLOT_FILE_FETCH_SUCCESS,
    SLOT_ORDERS_CREATE_ERROR,
    SLOT_ORDERS_CREATE_IN_PROGRESS,
    SLOT_ORDERS_CREATE_SUCCESS,
    SLOT_ORDERS_FETCH_ERROR,
    SLOT_ORDERS_FETCH_IN_PROGRESS,
    SLOT_ORDERS_FETCH_SUCCESS,
    SLOT_STRIP_SECRET_FETCH_ERROR,
    SLOT_STRIP_SECRET_FETCH_IN_PROGRESS,
    SLOT_STRIP_SECRET_FETCH_SUCCESS,
    UPDATE_IN_STREAM_FILE_ERROR,
    UPDATE_IN_STREAM_FILE_IN_PROGRESS,
    UPDATE_IN_STREAM_FILE_PROGRESS_SET,
    UPDATE_IN_STREAM_FILE_SUCCESS,
    UPDATE_OVERLAY_FILE_ERROR,
    UPDATE_OVERLAY_FILE_IN_PROGRESS,
    UPDATE_OVERLAY_FILE_SUCCESS,
} from '../constants/slotOrder';
import { FETCH_MY_SLOTS_SUCCESS } from '../constants/ads';

const mySlotOrders = (state = {
    inProgress: false,
    value: [],
    total: null,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case MY_SLOT_ORDERS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MY_SLOT_ORDERS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case MY_SLOT_ORDERS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            inProgress: false,
            value: [],
            total: null,
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            sortBy: DEFAULT_SORT_BY,
            order: DEFAULT_ORDER,
        };
    default:
        return state;
    }
};

const slotOrder = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SLOT_ORDERS_CREATE_IN_PROGRESS:
    case SLOT_ORDERS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SLOT_ORDERS_CREATE_SUCCESS:
    case SLOT_ORDERS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case SLOT_ORDERS_CREATE_ERROR:
    case SLOT_ORDERS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_CREATE_SLOT_ORDER:
        return {
            ...state,
            value: {},
        };
    case DISCONNECT_SET:
        return {
            inProgress: false,
            value: {},
        };
    default:
        return state;
    }
};

const confirmDialog = (state = {
    open: false,
    success: false,
    order: {},
}, action) => {
    switch (action.type) {
    case SLOT_CONFIRM_DIALOG_SHOW:
        return {
            ...state,
            open: true,
        };
    case SLOT_CONFIRM_DIALOG_HIDE:
        return {
            open: false,
            success: false,
            order: {},
        };
    case SLOT_CONFIRM_DIALOG_SUCCESS_SET:
        return {
            ...state,
            success: action.value,
        };
    case SLOT_ORDERS_CREATE_SUCCESS:
    case SLOT_ORDERS_FETCH_SUCCESS:
        return {
            ...state,
            order: action.value,
        };

    default:
        return state;
    }
};

const stripeSecret = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SLOT_STRIP_SECRET_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SLOT_STRIP_SECRET_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case SLOT_STRIP_SECRET_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const selectedMyAdSlot = (state = {
    list: {},
    checked: {},
}, action) => {
    switch (action.type) {
    case SELECTED_MY_AD_SLOT_SET:
        return {
            ...state,
            list: action.value,
        };
    case CHECKED_MY_AD_SLOT_SET:
        return {
            ...state,
            checked: action.value,
        };
    case FETCH_MY_SLOTS_SUCCESS: {
        const array = action.value;
        const list = { ...state.checked };
        if (array && array.length) {
            array.map((val) => {
                list[val._id] = false;
            });
        }

        return {
            ...state,
            checked: list,
        };
    }
    case SLOT_FILE_FETCH_SUCCESS:
    case UPDATE_OVERLAY_FILE_SUCCESS: {
        const list = { ...state.list };
        if (list && list[action.slotID]) {
            list[action.slotID] = {
                ...list[action.slotID],
                file_url: action.value,
            };
        }

        return {
            ...state,
            list: list,
        };
    }
    case UPDATE_IN_STREAM_FILE_SUCCESS: {
        const list = { ...state.list };
        if (action.slotIDs && action.slotIDs.length) {
            action.slotIDs.map((val) => {
                if (val && list[val]) {
                    list[val] = {
                        ...list[val],
                        file_url: action.value,
                    };
                }
            });
        }

        return {
            ...state,
            list: list,
        };
    }
    case DISCONNECT_SET:
        return {
            list: {},
            checked: {},
        };
    default:
        return state;
    }
};

const slotFile = (state = {
    inProgress: false,
    value: {},
    progress: null,
}, action) => {
    switch (action.type) {
    case UPDATE_OVERLAY_FILE_IN_PROGRESS:
    case UPDATE_IN_STREAM_FILE_IN_PROGRESS:
    case SLOT_FILE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPDATE_OVERLAY_FILE_SUCCESS:
    case UPDATE_IN_STREAM_FILE_SUCCESS:
    case SLOT_FILE_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case UPDATE_OVERLAY_FILE_ERROR:
    case UPDATE_IN_STREAM_FILE_ERROR:
    case SLOT_FILE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case UPDATE_IN_STREAM_FILE_PROGRESS_SET: {
        return {
            ...state,
            progress: action.value,
        };
    }
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
            progress: null,
        };
    default:
        return state;
    }
};

const selectedSlotTypes = (state = {
    '10_SEC_OVERLAY': [],
    '5_SEC_OVERLAY': [],
    '10_SEC_IN_STREAM': [],
}, action) => {
    switch (action.type) {
    case SELECTED_SLOT_TYPE_SET:
        return action.value;
    case DISCONNECT_SET:
        return {
            '10_SEC_OVERLAY': [],
            '5_SEC_OVERLAY': [],
            '10_SEC_IN_STREAM': [],
        };
    default:
        return state;
    }
};

export default combineReducers({
    mySlotOrders,
    slotOrder,
    confirmDialog,
    stripeSecret,
    selectedMyAdSlot,
    slotFile,
    selectedSlotTypes,
});
