import { combineReducers } from 'redux';
import {
    ACTIVE_MY_SLOTS_SESSION_SET,
    ADS_SLOT_CONFIRM_DIALOG_HIDE,
    ADS_SLOT_CONFIRM_DIALOG_SHOW,
    ADS_TAB_VALUE_SET,
    COMPANY_NAME_SET,
    CONTACT_PERSON_EMAIL_SET,
    CONTACT_PERSON_NAME_SET,
    CONTACT_PERSON_TWITTER_SET,
    DAY_SLOTS_STATUS_UPDATE_SET,
    EMAIL_ADDRESS_SET,
    FETCH_DAY_SESSIONS_ERROR,
    FETCH_DAY_SESSIONS_IN_PROGRESS,
    FETCH_DAY_SESSIONS_SUCCESS,
    FETCH_DAY_SLOTS_ERROR,
    FETCH_DAY_SLOTS_IN_PROGRESS,
    FETCH_DAY_SLOTS_SUCCESS,
    FETCH_MY_SESSIONS_ERROR,
    FETCH_MY_SESSIONS_IN_PROGRESS,
    FETCH_MY_SESSIONS_SUCCESS,
    FETCH_MY_SLOTS_ERROR,
    FETCH_MY_SLOTS_IN_PROGRESS,
    FETCH_MY_SLOTS_SUCCESS,
    INDUSTRY_CATEGORY_SET,
    LONG_DESCRIPTION_SET,
    MY_AD_SESSIONS_TAB_SET,
    MY_AD_SLOTS_TAB_SET,
    OVERLAY_SLOT_VALUE_SET,
    PROJECT_NAME_SET,
    PROJECT_TWITTER_SET,
    SELECTED_SLOTS_SET,
    SHORT_DESCRIPTION_SET,
    SPONSORSHIP_TYPE_SET,
    WEBSITE_URL_SET,
} from '../constants/ads';
import { DISCONNECT_SET } from '../constants/wallet';

const adsTabValue = (state = {
    value: 'DAY_1',
}, action) => {
    switch (action.type) {
    case ADS_TAB_VALUE_SET:
        return {
            value: action.value,
        };
    case DISCONNECT_SET:
        return {
            value: 'DAY_1',
        };

    default:
        return state;
    }
};

const overlaySlotValue = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case OVERLAY_SLOT_VALUE_SET:
        return {
            value: action.value,
        };
    case DISCONNECT_SET:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const adsSlotConfirmDialog = (state = {
    value: {},
    open: false,
}, action) => {
    switch (action.type) {
    case ADS_SLOT_CONFIRM_DIALOG_SHOW:
        return {
            value: action.value,
            open: true,
        };
    case ADS_SLOT_CONFIRM_DIALOG_HIDE:
        return {
            value: {},
            open: false,
        };

    default:
        return state;
    }
};

const myAdSlotsTab = (state = {
    value: 'DAY_1',
}, action) => {
    switch (action.type) {
    case MY_AD_SLOTS_TAB_SET:
        return {
            value: action.value,
        };
    case DISCONNECT_SET:
        return {
            value: 'DAY_1',
        };

    default:
        return state;
    }
};

const myAdSessionsTab = (state = {
    value: '5_sec',
}, action) => {
    switch (action.type) {
    case MY_AD_SESSIONS_TAB_SET:
        return {
            value: action.value,
        };
    case DISCONNECT_SET:
        return {
            value: '5_sec',
        };

    default:
        return state;
    }
};

const activeMyAdSessions = (state = {}, action) => {
    switch (action.type) {
    case ACTIVE_MY_SLOTS_SESSION_SET:
        return action.value;
    case DISCONNECT_SET:
        return {};

    default:
        return state;
    }
};

// sponsors registration
const projectName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case PROJECT_NAME_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const projectTwitter = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case PROJECT_TWITTER_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const shortDescription = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SHORT_DESCRIPTION_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const longDescription = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case LONG_DESCRIPTION_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const companyName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case COMPANY_NAME_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const emailAddress = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case EMAIL_ADDRESS_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const contactPersonName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case CONTACT_PERSON_NAME_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const contactPersonEmail = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case CONTACT_PERSON_EMAIL_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const contactPersonTwitter = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case CONTACT_PERSON_TWITTER_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const websiteURL = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case WEBSITE_URL_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const sponsorshipType = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SPONSORSHIP_TYPE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const industryCategory = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case INDUSTRY_CATEGORY_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const selectedSlots = (state = {
    value: new Set(),
}, action) => {
    switch (action.type) {
    case SELECTED_SLOTS_SET:
        return {
            value: action.value,
        };
    case DISCONNECT_SET:
        return {
            value: new Set(),
        };
    default:
        return state;
    }
};

// sessions
const daySessions = (state = {
    value: {},
    inProgress: false,
    day: '',
}, action) => {
    switch (action.type) {
    case FETCH_DAY_SESSIONS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_DAY_SESSIONS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: {
                ...state.value,
                [action.day]: action.value,
            },
        };
    case FETCH_DAY_SESSIONS_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

// slots
const daySlots = (state = {
    value: {},
    inProgress: false,
    day: '',
}, action) => {
    switch (action.type) {
    case FETCH_DAY_SLOTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_DAY_SLOTS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: {
                ...state.value,
                [action.day]: action.value,
            },
        };
    case DAY_SLOTS_STATUS_UPDATE_SET: {
        const obj = state.value;
        const updatedValue = action.value;
        if (obj && Object.keys(obj).length) {
            Object.keys(obj).map((val) => {
                if (val && obj[val] && obj[val].length) {
                    obj[val].map((value, index) => {
                        if (value && value._id) {
                            const findObject = updatedValue.find((val1) => val1._id === value._id);
                            if (findObject && findObject._id) {
                                obj[val][index] = {
                                    ...obj[val][index],
                                    status: findObject.status,
                                };
                            }
                        }
                    });
                }
            });
        }

        return {
            ...state,
            value: { ...obj },
        };
    }
    case FETCH_DAY_SLOTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

// my sessions
const mySessions = (state = {
    value: {},
    inProgress: false,
    day: '',
}, action) => {
    switch (action.type) {
    case FETCH_MY_SESSIONS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_MY_SESSIONS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: {
                ...state.value,
                [action.day]: action.value,
            },
        };
    case FETCH_MY_SESSIONS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            value: {},
            inProgress: false,
            day: '',
        };

    default:
        return state;
    }
};

// slots
const mySlots = (state = {
    value: {},
    inProgress: false,
    day: '',
}, action) => {
    switch (action.type) {
    case FETCH_MY_SLOTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_MY_SLOTS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: {
                ...state.value,
                [action.day]: action.value,
            },
        };
    case FETCH_MY_SLOTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            value: {},
            inProgress: false,
            day: '',
        };

    default:
        return state;
    }
};

export default combineReducers({
    adsTabValue,
    overlaySlotValue,
    adsSlotConfirmDialog,

    myAdSlotsTab,
    myAdSessionsTab,
    activeMyAdSessions,

    projectName,
    projectTwitter,
    shortDescription,
    longDescription,
    companyName,
    emailAddress,
    contactPersonName,
    contactPersonEmail,
    contactPersonTwitter,
    websiteURL,
    sponsorshipType,
    industryCategory,
    selectedSlots,

    daySessions,
    daySlots,
    mySessions,
    mySlots,
});
