import React, { useEffect } from 'react';
import './index.css';
import CheckIcon from '@mui/icons-material/Check';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import generalTicket from '../../assets/tickets/general.avif';
import earlyTicket from '../../assets/tickets/early_bird.avif';
import lateTicket from '../../assets/tickets/late.avif';
import vipTicket from '../../assets/tickets/vip.avif';
import vipDevTicket from '../../assets/tickets/vip_dev.avif';
import loyaltyTicket from '../../assets/tickets/loyalty.avif';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import withRouter from '../../components/WithRouter';
import { showMessage } from '../../actions/snackbar';
import {
    fetchEligibilityCheck,
    fetchTicketType,
    fetchTicketTypeWithID,
    setEligibilityCheckLogin,
    showEligibilityCheckDialog,
} from '../../actions/buyTickets';
import NetworkImages from '../../components/NetworkImages';
import moment from 'moment';
import ClassNames from 'classnames';
import EligibilityCheckDialog from './EligibilityCheckDialog';
import { showSignInDialog } from '../../actions/signIn';
import Counter2 from '../LiveOn/Counter2';
import Counter from '../LiveOn/Counter';
import variables from '../../utils/variables';

const SelectTickets = (props) => {
    useEffect(() => {
        if (props.typeInfo && (!props.typeInfo.length || props.typeInfo.length === 1) && !props.inProgress) {
            props.fetchTicketType();
        }

        const intervalId = setInterval(() => {
            props.fetchTicketType();
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const earlyBird = ['Conference Access', 'Food & Beverages (F&B) Access', 'Merchandise Access', 'Hackmos access'];
    const loyaltyPass = ['Conference Access', 'Food & Beverages (F&B) Access', 'Merchandise Access', 'Hackmos access'];
    const generalAdmission = ['Conference Access', 'Food & Beverages (F&B) Access', 'Merchandise Access', 'Hackmos access'];
    const lateAdmission = ['Conference Access', 'Food & Beverages (F&B) Access', 'Merchandise Access', 'Hackmos access'];
    const executiveVIP = ['Conference Access', 'Food & Beverages (F&B) Access', 'Merchandise Access', 'Hackmos access', 'Access to Executive Lounge', 'Exclusive Pre-Event Invitation', 'Afterparty Ticket', 'Access to the Pitching Competition'];
    const sponsorDev = ['Conference Access', 'Food & Beverages (F&B) Access', 'Merchandise Access', 'Hackmos participation', 'Subsidized Flights', 'Accommodation'];

    const handleClick = (item) => {
        if (item._id) {
            props.router.navigate('/tickets/ticketType/' + (item._id));
        } else {
            props.showMessage('Something went wrong!');
        }
    };

    const handleCheckEligibility = (item) => {
        if (props.profileDetails && props.profileDetails._id) {
            props.fetchEligibilityCheck(item._id, (result, error) => {
                if (error === 'Error occurred while verifying the JWT token.') {
                    handleCheckEligibility(item);
                    return;
                }

                if (result && result > 0) {
                    props.showEligibilityCheckDialog(true, item._id);
                    return;
                }

                props.showEligibilityCheckDialog(false, item._id);
            });
        } else {
            props.setEligibilityCheckLogin(true, item._id);
            props.showSignInDialog();
        }
    };

    const handleKnowMore = (e) => {
        e.stopPropagation();
        window.open('https://cosmoverse.org/tickets');
    };

    let ticketType = props.typeInfo && props.typeInfo.length && props.typeInfo.find((value) => {
        if (value && value.start_time && moment().diff(value.start_time) < 0) {
            return value;
        }

        return null;
    });

    if (ticketType && !ticketType.available_tickets) {
        ticketType = null;
    }

    return (
        <div className="select_tickets_page">
            <div className="header">
                <h2>Select your tickets</h2>
                <p> October 21-23,OCT,2024{'    Grand Hyatt Dubai, UAE'}</p>
                {ticketType
                    ? <>
                        <div className="section3">
                            {ticketType.name + ' ' + variables[props.lang]['late_pass_content']}
                            <span>{moment(ticketType.start_time).format('Do MMMM, YYYY')}</span>
                        </div>
                        <Counter ticketInfo={ticketType}/>
                    </> : null}
            </div>
            <div className="select_cards">
                {(props.inProgress && (props.typeInfo && (!props.typeInfo.length || (props.typeInfo.length === 1)))) ||
                (props.inProgress && !props.typeInfo)
                    ? <CircularProgress/>
                    : props.typeInfo && props.typeInfo.length
                        ? props.typeInfo.map((cardData, index) => {
                            const total = cardData.price && cardData.price.fiat && cardData.price.fiat.amount && (cardData.price.fiat.amount / 100);
                            const buyNow = cardData && cardData.start_time && moment().diff(cardData.start_time) > 0 &&
                                (cardData && cardData.end_time && moment().diff(cardData.end_time) < 0) &&
                                (cardData && cardData.available_tickets);
                            // const percentage = ((100 * cardData.available_tickets) / cardData.total_tickets > 50)
                            //     ? 'green'
                            //     : ((100 * cardData.available_tickets) / cardData.total_tickets > 10)
                            //         ? 'yellow'
                            //         : ((100 * cardData.available_tickets) / cardData.total_tickets > 0)
                            //             ? 'red'
                            //             : 'gray';
                            const disableData = (cardData && cardData.start_time && moment().diff(cardData.start_time) < 0) ||
                                (cardData && cardData.end_time && moment().diff(cardData.end_time) > 0) ||
                                (cardData && !cardData.available_tickets);

                            let checkEligible = true;
                            let eligibleBuyNow = false;
                            if (cardData._id && props.eligibilityCheck && props.eligibilityCheck.id &&
                                (cardData._id === props.eligibilityCheck.id) && !props.eligibilityCheck.value) {
                                checkEligible = false;
                            } else if (cardData._id && props.eligibilityCheck && props.eligibilityCheck.id &&
                                (cardData._id === props.eligibilityCheck.id) && props.eligibilityCheck.value && props.eligibilityCheck.value > 0) {
                                eligibleBuyNow = true;
                            }

                            return (
                                <div
                                    key={index}
                                    className={ClassNames('card_details', buyNow && checkEligible ? '' : 'disabled')}
                                    onClick={checkEligible && eligibleBuyNow
                                        ? () => handleClick(cardData)
                                        : cardData.name === 'Loyalty Pass'
                                            ? null
                                            : buyNow ? () => handleClick(cardData) : null}>
                                    <div className="spinner">
                                        {cardData.name === 'Early Bird'
                                            ? <img alt="animation" src={earlyTicket}/>
                                            : cardData.name === 'Loyalty Pass'
                                                ? <img alt="animation" src={loyaltyTicket}/>
                                                : cardData.name === 'General Admission' || cardData.name === 'General Admission Batch #1' ||
                                                cardData.name === 'General Admission Tickets Batch #1'
                                                    ? <img alt="animation" src={generalTicket}/>
                                                    : cardData.name === 'Late Admission'
                                                        ? <img alt="animation" src={lateTicket}/>
                                                        : cardData.name === 'Executive Tickets' || cardData.name === 'Executive Ticket'
                                                            ? <img alt="animation" src={vipTicket}/>
                                                            : cardData.name === 'Sponsor A Dev'
                                                                ? <img alt="animation" src={vipDevTicket}/>
                                                                : null}
                                    </div>
                                    <Tooltip title={cardData.name}>
                                        <p className="ticket_type">{cardData.name}</p>
                                    </Tooltip>
                                    <p className="ticket_price"><NetworkImages
                                        name={cardData.price && cardData.price.fiat && cardData.price.fiat.currency}/>{total}
                                    </p>
                                    {cardData.name === 'Loyalty Pass' &&
                                    <div className="loyalty_content">
                                        exclusively for emails that registered and attended Cosmoverse in 2022 & 2023!
                                    </div>}
                                    {cardData.name === 'Early Bird'
                                        ? <div className={disableData ? 'more_options margin_update' : 'more_options'}>
                                            {earlyBird.map((item, indexCheck) => (
                                                <p key={indexCheck}>
                                                    <CheckIcon/>
                                                    {item}
                                                </p>
                                            ))}
                                        </div> : cardData.name === 'Loyalty Pass'
                                            ? <div className={disableData ? 'more_options margin_update' : 'more_options'}>
                                                {loyaltyPass.map((item, indexCheck) => (
                                                    <p key={indexCheck}>
                                                        <CheckIcon/>
                                                        {item}
                                                    </p>
                                                ))}
                                            </div> : cardData.name === 'General Admission' || cardData.name === 'General Admission Batch #1' ||
                                            cardData.name === 'General Admission Tickets Batch #1'
                                                ? <div className={disableData ? 'more_options margin_update' : 'more_options'}>
                                                    {generalAdmission.map((item, indexCheck) => (
                                                        <p key={indexCheck}>
                                                            <CheckIcon/>
                                                            {item}
                                                        </p>
                                                    ))}
                                                </div> : cardData.name === 'Late Admission'
                                                    ? <div className={disableData ? 'more_options margin_update' : 'more_options'}>
                                                        {lateAdmission.map((item, indexCheck) => (
                                                            <p key={indexCheck}>
                                                                <CheckIcon/>
                                                                {item}
                                                            </p>
                                                        ))}
                                                    </div> : cardData.name === 'Executive Tickets' || cardData.name === 'Executive Ticket'
                                                        ? <div className={disableData ? 'more_options margin_update' : 'more_options'}>
                                                            {executiveVIP.map((item, indexCheck) => (
                                                                <p key={indexCheck}>
                                                                    <CheckIcon/>
                                                                    {item}
                                                                </p>
                                                            ))}
                                                        </div> : cardData.name === 'Sponsor A Dev'
                                                            ? <div className={disableData ? 'more_options margin_update' : 'more_options'}>
                                                                {sponsorDev.map((item, indexCheck) => (
                                                                    <p key={indexCheck}>
                                                                        <CheckIcon/>
                                                                        {item}
                                                                    </p>
                                                                ))}
                                                            </div> : null}
                                    {disableData ? null
                                        : <Button className="know_more" onClick={(e) => handleKnowMore(e)}>
                                                                Know more
                                        </Button>}
                                    <div className="actions">
                                        {cardData && cardData.start_time && moment().diff(cardData.start_time) < 0
                                            ? <Button disabled className="coming_soon">
                                                Coming soon - {moment(cardData.start_time).format('MMMM Do')}
                                                <Counter2 ticketInfo={cardData}/>
                                            </Button>
                                            : (cardData && cardData.end_time && moment().diff(cardData.end_time) > 0) ||
                                            (cardData && !cardData.available_tickets)
                                                ? <Button disabled className="sold_out">
                                                    Sold out
                                                </Button>
                                                : cardData && cardData.start_time && moment().diff(cardData.start_time) > 0 &&
                                                (cardData && cardData.end_time && moment().diff(cardData.end_time) < 0) &&
                                                (cardData && cardData.available_tickets) && cardData.name === 'Loyalty Pass' && !checkEligible
                                                    ? <Button disabled className="buy_now">
                                                        Not Eligible
                                                    </Button>
                                                    : cardData && cardData.start_time && moment().diff(cardData.start_time) > 0 &&
                                                    (cardData && cardData.end_time && moment().diff(cardData.end_time) < 0) &&
                                                    (cardData && cardData.available_tickets) && cardData.name === 'Loyalty Pass' && !checkEligible
                                                        ? <Button disabled className="buy_now">
                                                            Not Eligible
                                                        </Button>
                                                        : cardData && cardData.start_time && moment().diff(cardData.start_time) > 0 &&
                                                        (cardData && cardData.end_time && moment().diff(cardData.end_time) < 0) &&
                                                        (cardData && cardData.available_tickets) && cardData.name === 'Loyalty Pass' &&
                                                        checkEligible && eligibleBuyNow
                                                            ? <Button className="buy_now">
                                                                Buy Now
                                                            </Button>
                                                            : cardData && cardData.start_time && moment().diff(cardData.start_time) > 0 &&
                                                            (cardData && cardData.end_time && moment().diff(cardData.end_time) < 0) &&
                                                            (cardData && cardData.available_tickets) && cardData.name === 'Loyalty Pass'
                                                                ? <Button
                                                                    className="buy_now"
                                                                    onClick={() => handleCheckEligibility(cardData)}>
                                                                    Check Eligibility
                                                                </Button>
                                                                : cardData && cardData.start_time && moment().diff(cardData.start_time) > 0 &&
                                                                (cardData && cardData.end_time && moment().diff(cardData.end_time) < 0) &&
                                                                (cardData && cardData.available_tickets)
                                                                    ? <Button className="buy_now">
                                                                        Buy Now
                                                                    </Button>
                                                                    : null}
                                        {/* {cardData && cardData.start_time && moment().diff(cardData.start_time) > 0 && */}
                                        {/* (cardData && cardData.end_time && moment().diff(cardData.end_time) < 0) && */}
                                        {/* (cardData && cardData.available_tickets) */}
                                        {/*     ? <div className="tickets_left"> */}
                                        {/*         <p className={percentage || ''}> */}
                                        {/*             <span/> */}
                                        {/*             {cardData.available_tickets ? cardData.available_tickets : '0'} Tickets */}
                                        {/*             left */}
                                        {/*         </p> */}
                                        {/*     </div> */}
                                        {/*     : null} */}
                                    </div>
                                </div>
                            );
                        })
                        : <div className="no_data">No Data</div>}
            </div>
            <EligibilityCheckDialog/>
        </div>
    );
};

SelectTickets.propTypes = {
    fetchEligibilityCheck: PropTypes.func.isRequired,
    fetchTicketType: PropTypes.func.isRequired,
    fetchTicketTypeWithID: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string.isRequired,
        }).isRequired,
        params: PropTypes.shape({
            id: PropTypes.string,
            ticketID: PropTypes.string,
        }),
    }).isRequired,
    setEligibilityCheckLogin: PropTypes.func.isRequired,
    showEligibilityCheckDialog: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    showSignInDialog: PropTypes.func.isRequired,
    typeInfo: PropTypes.array.isRequired,
    eligibilityCheck: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        typeInfo: state.buyTickets.ticketType.value,
        inProgress: state.buyTickets.ticketType.inProgress,
        profileDetails: state.myAccount.profileDetails.value,
        eligibilityCheck: state.buyTickets.eligibilityCheck.value,
        lang: state.language,
    };
};

const actionToProps = {
    showMessage: showMessage,
    showSignInDialog,
    fetchEligibilityCheck,
    fetchTicketType,
    fetchTicketTypeWithID,
    showEligibilityCheckDialog,
    setEligibilityCheckLogin,
};

export default withRouter(connect(stateToProps, actionToProps)(SelectTickets));
