import { combineReducers } from 'redux';
import {
    AMINO_SIGN_SUCCESS,
    CONNECT_COSMO_STATION_ERROR,
    CONNECT_COSMO_STATION_IN_PROGRESS,
    CONNECT_COSMO_STATION_SUCCESS,
    CONNECT_FLIX_ACCOUNT_ERROR,
    CONNECT_FLIX_ACCOUNT_IN_PROGRESS,
    CONNECT_FLIX_ACCOUNT_SUCCESS,
    CONNECT_KEPLR_ACCOUNT_ERROR,
    CONNECT_KEPLR_ACCOUNT_IN_PROGRESS,
    CONNECT_KEPLR_ACCOUNT_SUCCESS,
    CONNECT_LEAP_ACCOUNT_ERROR,
    CONNECT_LEAP_ACCOUNT_IN_PROGRESS,
    CONNECT_LEAP_ACCOUNT_SUCCESS,
    DISCONNECT_SET,
    FLIX_ACCOUNT_DISCONNECT_SET,
    KEPLR_ACCOUNT_KEYS_SET,
    WALLET_DISCONNECT_SET,
} from '../../constants/wallet';
import { PROFILE_DETAILS_FETCH_SUCCESS } from '../../constants/myAccount';
// import { CLAIM_DIALOG_HIDE } from '../../constants/myAccount';

const connection = (state = {
    inProgress: false,
    address: '',
    addressInProgress: false,
    keys: {},
}, action) => {
    switch (action.type) {
    case CONNECT_KEPLR_ACCOUNT_IN_PROGRESS:
    case CONNECT_COSMO_STATION_IN_PROGRESS:
    case CONNECT_LEAP_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            addressInProgress: true,
        };
        // case CONNECT_BC_ACCOUNT_IN_PROGRESS:
        // case VERIFY_ACCOUNT_IN_PROGRESS:
        //     return {
        //         ...state,
        //         inProgress: true,
        //     };
    case CONNECT_KEPLR_ACCOUNT_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.length &&
            action.value[0] && action.value[0].address ? action.value[0].address : '',
        };
    case CONNECT_COSMO_STATION_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.address,
            keys: action.value && action.value.address ? {
                ...action.value,
                bech32Address: action.value.address,
            } : {},
        };
    case CONNECT_LEAP_ACCOUNT_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.length &&
            action.value[0] && action.value[0].address ? action.value[0].address : '',
        };
    case CONNECT_KEPLR_ACCOUNT_ERROR:
    case CONNECT_COSMO_STATION_ERROR:
    case CONNECT_LEAP_ACCOUNT_ERROR:
    case AMINO_SIGN_SUCCESS:
        return {
            ...state,
            inProgress: false,
            addressInProgress: false,
        };

    case KEPLR_ACCOUNT_KEYS_SET:
        return {
            ...state,
            keys: action.value,
        };
    case DISCONNECT_SET:
    case WALLET_DISCONNECT_SET:
        return {
            inProgress: false,
            address: '',
        };
    default:
        return state;
    }
};

const flixConnection = (state = {
    inProgress: false,
    address: '',
}, action) => {
    switch (action.type) {
    case CONNECT_FLIX_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CONNECT_FLIX_ACCOUNT_SUCCESS:
        return {
            inProgress: false,
            address: action.value && action.value.length &&
                action.value[0] && action.value[0].address,
        };
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return {
            ...state,
            address: action.value && action.value.bc_account && action.value.bc_account.address,
        };
    case CONNECT_FLIX_ACCOUNT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case FLIX_ACCOUNT_DISCONNECT_SET:
        // case CLAIM_DIALOG_HIDE:
        return {
            inProgress: false,
            address: '',
        };
    default:
        return state;
    }
};

export default combineReducers({
    connection,
    flixConnection,
});
